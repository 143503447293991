import React from 'react';
import YearChartVertical from "../YearChartVertical/YearChartVertical";

function MoviesByYear() {
    return (
        <YearChartVertical></YearChartVertical>
    );
}

export default MoviesByYear;

