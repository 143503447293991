import React from 'react';
import DecadeChartVertical from "../DecadeChartVertical/DecadeChartVertical";

function MoviesByDecade() {
    return (
        <DecadeChartVertical></DecadeChartVertical>
    );
}

export default MoviesByDecade;

